<template>
  <div>
    <FormWizard
      :form-steps-name="steps"
      :schema-array="schemas"
      :init-form="initForm"
      :submit-step-index="1"
      finish-text="送出"
      @submit-form="submit"
      @finish="verifyCodeCheck"
      v-show="!isFinish"
    >
      <template v-slot="{ currentFormData }">
        <FormWizardStep :step="0">
          <div class="grid-row">
            <div class="col-12">
              <FormRadio
                field-name="unitType"
                field-label="帳號類型"
                :options="[
                  {
                    id: 1,
                    name: '企業團體',
                  },
                  {
                    id: 2,
                    name: '個人',
                  },
                ]"
              />
            </div>
            <!-- 企業團體 -->
            <template v-if="currentFormData.unitType === 1">
              <div class="col-12_md-6">
                <FormInput field-name="unitName" field-label="單位全銜" />
              </div>
              <div class="col-12_md-6">
                <FormInput field-name="unNumber" field-label="統一編號" />
              </div>
              <div class="col-12_md-6">
                <FormInput field-name="headerName" field-label="負責人姓名" />
              </div>
            </template>
            <!-- 個人 -->
            <template v-else>
              <div class="col-12_md-6">
                <FormInput field-name="name" field-label="姓名" />
              </div>
              <div class="col-12_md-6">
                <FormInput field-name="iDNumber" field-label="身分證字號" :is-async="true"/>
              </div>
            </template>
          </div>
          <div class="grid-row">
            <div class="col-12_md-6">
              <FormInput field-name="password" field-label="密碼" field-type="password">
                <template #notify>至少12碼，且包含英文大小寫、特殊符號與數字</template>
              </FormInput>
            </div>
            <div class="col-12_md-6">
              <FormInput
                field-name="confirmPassword"
                field-label="確認密碼"
                field-type="password"
              />
            </div>
          </div>

          <hr class="form-hr" />

          <div class="form-title">主要聯絡人</div>
          <div class="grid-row">
            <div class="col-12_md-6">
              <FormInput field-name="contactName" field-label="姓名" :field-helper="currentFormData.unitType === 2 ? '可與會員姓名相同' : null"/>
            </div>
            <div class="col-12_md-6">
              <FormInput field-name="contactEmail" field-label="Email" field-type="email" />
            </div>
            <div class="col-12_md-6">
              <FormInput field-name="contactTel" field-label="電話" field-type="tel" />
            </div>
            <div class="col-12_md-6">
              <FormInput field-name="contactMobile" field-label="手機" />
            </div>
            <div class="col-12_md-6">
              <FormInput field-name="contactFax" field-label="傳真" :is-required="false" />
            </div>
          </div>
          <div class="grid-row">
            <div class="col-12">
              <FormInput field-label="聯絡地址" field-name="address" />
            </div>
          </div>

          <hr class="form-hr" />

          <div class="form-title">其他聯絡人</div>
          <div class="grid-row">
            <div class="col-12_md-6">
              <FormInput field-name="otherName" field-label="姓名" :is-required="false" />
            </div>
            <div class="col-12_md-6">
              <FormInput field-name="otherEmail" field-label="Email" field-type="email" :is-required="false" />
            </div>
            <div class="col-12_md-6">
              <FormInput field-name="otherTel" field-label="電話" field-type="tel" :is-required="false" />
            </div>
            <div class="col-12_md-6">
              <FormInput field-name="otherMobile" field-label="手機" :is-required="false" />
            </div>
          </div>

          <hr class="form-hr" />

          <div class="form-title">文件證明</div>
          <div class="form-promp is-notify">限 PDF 或 JPG 格式，各檔案不得超過3MB</div>
          <div class="grid-row" v-if="currentFormData.unitType === 1">
            <div class="col-12_md-4">
              <FileInput
                field-label="商業登記證明影本或立案證明影本"
                field-name="file1"
                :limit-size="3"
                :limit-type="['jpg', 'jpeg', 'pdf']"
              />
            </div>
            <div class="col-12_md-4">
              <FileInput
                field-label="負責人身份證正/反面影本"
                field-name="file2"
                :limit-size="3"
                :limit-type="['jpg', 'jpeg', 'pdf']"
              />
            </div>
            <div class="col-12_md-4">
              <FileInput
                field-label="簡介(內容請包含辦理類似活動實績等)"
                field-name="file3"
                :limit-size="3"
                :limit-type="['jpg', 'jpeg', 'pdf']"
              />
            </div>
          </div>

          <div class="grid-row" v-else>
            <div class="col-12_md-4">
              <FileInput
                field-label="身份證正/反面影本"
                field-name="file1"
                :limit-size="3"
                :limit-type="['jpg', 'jpeg', 'pdf']"
              />
            </div>
          </div>

          <div class="panel">
            <div class="h4 mb-1">臺北流行音樂中心個人資料蒐集、處理及利用同意書</div>
            <div class="scroll-wrapper">
              依據個人資料保護法等相關規定，臺北流行音樂中心(以下簡稱本中心)有義務告知以下事項，請您於使用「場地租借系統」服務時務必詳閱，確認同意後再送交申請資料。<br><br>
              一、臺北市流行音樂中心為提供「場地租借系統」相關服務，蒐集您的個人資料可分為以下目的： 進行行銷、契約、類似契約或其他法律關係事務、
              資(通)訊服務、客戶管理與服務、調查統計與研究分析、資（通）訊與資料庫管理、其他電子商務服務及其他財政服務及配合財政部電子發票整合服務。<br><br>
              二、蒐集個人資料之類別： <br>
                C001 識別個人者：姓名、職稱、通訊及戶籍地址、住家電話號碼、行動電話、網路平臺申請之帳號、電子郵遞地址、電子簽章、提供網路身分認證或申辦查詢服務之紀錄及其他任何可辨識資料本人者等。<br>
                C002 辨識財務者：例如：金融機構帳戶之號碼與姓名、信用卡或簽帳卡之號碼、個人之其他號碼或帳戶等。<br>
                C003 政府資料中之識別者：例如：身份證統一編號等。<br><br>
              三、個人資料利用之期間、對象、地區及方式：<br>
                <ol>
                  <li>1. 期間：個人資料蒐集之特定目的存續期間或本中心依相關法令或契約約定執行業務所必須之保存期間。</li>
                  <li>2. 對象：本中心及所屬機關、本中心業務委外機構及與本中心有業務往來之機構。</li>
                  <li>3. 地區：本國領域範圍、本中心所屬機關所在地、本中心業務委外機構所在地、與本中心有業務往來之機構所在地。</li>
                  <li>4. 方式：用於提供「場地租借系統」服務，並使用於本中心各項服務及統計研究分析，包含依您授權存取之第三方網站或應用程式的要求，將個人資料提供予該服務機關。</li>
                </ol> <br>
              五、您得隨時於「會員資料」功能中查閱、補充、更正您的個人資料(變更公司名稱及姓名須向本中心提出書面申請)。
              本中心對於所蒐集之會員個人資料，會員本人得依個人資料保護法對本中心行使以下權利：查詢或請求閱覽、請求製給複製本、請求補充或更正、請求停止蒐集、處理或利用、請求刪除。會員如欲行使上述權利，應與本中心客服聯絡申請。<br><br>
              六、如拒絕提供加入會員所需之必要資料，將可能導致無法完成會員申請或享 受完整之服務內容。<br><br>
              七、本中心將保留帳號申請核准與否之權利。
            </div>
          </div>

          <Field v-slot="{ handleChange, value, errors }" name="acceptTerm" type="checkbox">
            <div class="form-checkbox" :class="{'is-invalid': errors.length > 0}">
              <label for="acceptTerm">
                <input
                  id="acceptTerm"
                  type="checkbox"
                  name="acceptTerm"
                  @change="handleChange(!value)"
                  :checked="value"
                />
                <span></span>
                我已詳閱並同意上述注意事項
              </label>
            </div>
          </Field>
          <div class="form-promp is-invalid">
            <ErrorMessage name="acceptTerm" />
          </div>
        </FormWizardStep>

        <FormWizardStep :step="1">
          <div class="panel" v-if="currentFormData.unitType === 1">
            <div class="panel-block">
              <ul class="labelList">
                <li class="labelList-item">
                  <div class="labelList-item-label">帳號類別：</div>
                  <div class="labelList-item-content">企業團體</div>
                </li>
                <li class="labelList-item">
                  <div class="labelList-item-label">單位全銜：</div>
                  <div class="labelList-item-content">{{ currentFormData.unitName }}</div>
                </li>
                <li class="labelList-item">
                  <div class="labelList-item-label">負責人姓名：</div>
                  <div class="labelList-item-content">{{ currentFormData.headerName }}</div>
                </li>
                <li class="labelList-item">
                  <div class="labelList-item-label">統一編號：</div>
                  <div class="labelList-item-content">{{ currentFormData.unNumber }}</div>
                </li>
              </ul>
            </div>
            <div class="panel-block">
              <div class="grid-row">
                <div class="col-12_md-6">
                  <div class="panel-block-title">主要聯絡人</div>
                  <ul class="labelList">
                    <li class="labelList-item">
                      <div class="labelList-item-label">姓名：</div>
                      <div class="labelList-item-content">{{ currentFormData.contactName }}</div>
                    </li>
                    <li class="labelList-item">
                      <div class="labelList-item-label">Email：</div>
                      <div class="labelList-item-content">{{ currentFormData.contactEmail }}</div>
                    </li>
                    <li class="labelList-item">
                      <div class="labelList-item-label">連絡電話：</div>
                      <div class="labelList-item-content">{{ currentFormData.contactTel }}</div>
                    </li>
                    <li class="labelList-item">
                      <div class="labelList-item-label">手機號碼：</div>
                      <div class="labelList-item-content">{{ currentFormData.contactMobile }}</div>
                    </li>
                    <li class="labelList-item">
                      <div class="labelList-item-label">傳真：</div>
                      <div class="labelList-item-content">{{ currentFormData.contactFax || '-' }}</div>
                    </li>
                    <li class="labelList-item">
                      <div class="labelList-item-label">聯絡地址：</div>
                      <div class="labelList-item-content">
                        {{ currentFormData.address }}
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="col-12_md-6">
                  <div class="panel-block-title">其他聯絡人</div>
                  <ul class="labelList">
                    <li class="labelList-item">
                      <div class="labelList-item-label">姓名：</div>
                      <div class="labelList-item-content">{{ currentFormData.otherName || '-' }}</div>
                    </li>
                    <li class="labelList-item">
                      <div class="labelList-item-label">Email：</div>
                      <div class="labelList-item-content">
                        {{ currentFormData.otherEmail || '-' }}
                      </div>
                    </li>
                    <li class="labelList-item">
                      <div class="labelList-item-label">連絡電話：</div>
                      <div class="labelList-item-content">
                        {{ currentFormData.otherTel || '-' }}
                      </div>
                    </li>
                    <li class="labelList-item">
                      <div class="labelList-item-label">手機號碼：</div>
                      <div class="labelList-item-content">
                        {{ currentFormData.otherMobile || '-' }}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="panel-block">
              <div class="panel-block-title">文件證明</div>
              <div class="grid-row">
                <div class="col-12_md-4">
                  <FileInputReadonly
                    field-label="商業登記證明影本或立案證明影本"
                    :readonly-info="currentFormData.file1"
                  />
                </div>
                <div class="col-12_md-4">
                  <FileInputReadonly
                    field-label="負責人身份證正/反面影本"
                    :readonly-info="currentFormData.file2"
                  />
                </div>
                <div class="col-12_md-4">
                  <FileInputReadonly
                    field-label="簡介(內容請包含辦理類似活動實績等)"
                    :readonly-info="currentFormData.file3"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="panel" v-else>
            <div class="panel-block">
              <ul class="labelList">
                <li class="labelList-item">
                  <div class="labelList-item-label">帳號類別：</div>
                  <div class="labelList-item-content">個人</div>
                </li>
                <li class="labelList-item">
                  <div class="labelList-item-label">姓名：</div>
                  <div class="labelList-item-content">{{ currentFormData.contactName }}</div>
                </li>
                <li class="labelList-item">
                  <div class="labelList-item-label">身分證字號：</div>
                  <div class="labelList-item-content">{{ currentFormData.iDNumber }}</div>
                </li>
              </ul>
            </div>
            <div class="panel-block">
              <div class="grid-row">
                <div class="col-12_md-6">
                  <div class="panel-block-title">聯絡方式</div>
                  <ul class="labelList">
                    <li class="labelList-item">
                      <div class="labelList-item-label">Email：</div>
                      <div class="labelList-item-content">{{ currentFormData.contactEmail }}</div>
                    </li>
                    <li class="labelList-item">
                      <div class="labelList-item-label">連絡電話：</div>
                      <div class="labelList-item-content">{{ currentFormData.contactTel }}</div>
                    </li>
                    <li class="labelList-item">
                      <div class="labelList-item-label">手機號碼：</div>
                      <div class="labelList-item-content">{{ currentFormData.contactMobile }}</div>
                    </li>
                    <li class="labelList-item">
                      <div class="labelList-item-label">傳真：</div>
                      <div class="labelList-item-content">{{ currentFormData.contactFax || '-' }}</div>
                    </li>
                    <li class="labelList-item">
                      <div class="labelList-item-label">聯絡地址：</div>
                      <div class="labelList-item-content">
                        {{ currentFormData.address }}
                      </div>
                    </li>
                  </ul>
                </div>
                <div class="col-12_md-6">
                  <div class="panel-block-title">其他聯絡人</div>
                  <ul class="labelList">
                    <li class="labelList-item">
                      <div class="labelList-item-label">姓名：</div>
                      <div class="labelList-item-content">{{ currentFormData.otherName || '-' }}</div>
                    </li>
                    <li class="labelList-item">
                      <div class="labelList-item-label">Email：</div>
                      <div class="labelList-item-content">
                        {{ currentFormData.otherEmail || '-' }}
                      </div>
                    </li>
                    <li class="labelList-item">
                      <div class="labelList-item-label">連絡電話：</div>
                      <div class="labelList-item-content">
                        {{ currentFormData.otherTel || '-' }}
                      </div>
                    </li>
                    <li class="labelList-item">
                      <div class="labelList-item-label">手機號碼：</div>
                      <div class="labelList-item-content">
                        {{ currentFormData.otherMobile || '-' }}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="panel-block">
              <div class="panel-block-title">文件證明</div>
              <div class="grid-row">
                <div class="col-12_md-4">
                  <FileInputReadonly
                    field-label="身份證正/反面"
                    :readonly-info="currentFormData.file1"
                  />
                </div>
              </div>
            </div>
          </div>
        </FormWizardStep>

        <FormWizardStep :step="2">
          <div class="grid-row">
            <div class="col-12_md-7">
              <p>已傳送驗證碼至您的電子信箱: <strong>{{currentFormData.contactEmail}}</strong></p>
              <small>請至您的電子信箱收取8位數字驗證碼，並填入以下欄位後，按下送出，即可完成帳號的申請。</small>
              <div class="form-item">
                <div class="form-label">E-mail 驗證碼</div>
                <input type="text" class="form-input" placeholder="請輸入信件中8位數字驗證碼" v-model="verifyCode" :disabled="!isVerifyMailSend">
                <div class="form-promp">
                  <div v-if="isVerifyMailSend">
                    - 驗證信的有效期限為10分鐘<br />
                    - 如果您一直沒有收到驗證碼，請您嘗試<a href="#" @click.prevent="verifyMailSend" style="color: #FF5000;">重新發送驗證碼</a>。
                  </div>
                  <div v-else>發送中...</div>
                </div>
              </div>
            </div>
            <div class="col-12_md-5 d-none-mobile text-center">
              <img src="@/assets/images/email.svg" alt="">
            </div>
          </div>
        </FormWizardStep>
      </template>
    </FormWizard>

    <!-- 完成 -->
    <div class="text-center pt-5 pb-5" v-if="isFinish">
      <Vue3Lottie :animationData="FinishJSON" width="150px" :loop="false"/>
      <div class="h3">謝謝您!您已經完成帳號申請!</div>
      <p>為了確保您的權益,需要1~3個工作天檢核您提供的資料是否有效。<br>我們會將審查結果Email至您已驗證成功的電子信箱,請您留意收信,謝謝!</p>
      <div class="mt-5">
        <RouterLink to="/login" class="btn btn-md btn-primary">返回登入</RouterLink>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { signup as schemas } from '@/utils/schema';
import { mapActions, mapState } from 'pinia';
import { useLoadingScreen } from '@/store';
import { useFormStore } from '@/store/form';
import { Vue3Lottie } from 'vue3-lottie';
import FinishJSON from '@/assets/images/finish.json';
import { Field, ErrorMessage } from 'vee-validate';
import FormWizard from '@/components/FormWizard.vue';
import FormWizardStep from '@/components/FormWizardStep.vue';
import FormInput from '@/components/Input/FormInput.vue';
import FormRadio from '@/components/Input/FormRadio.vue';
import FileInput from '@/components/Input/FileInput.vue';
import FileInputReadonly from '@/components/Input/FileInputReadonly.vue';
import api from '@/api/api';

export default {
  components: {
    FormWizard,
    FormWizardStep,
    Field,
    ErrorMessage,
    Vue3Lottie,
    FormInput,
    FormRadio,
    FileInput,
    FileInputReadonly,
  },
  data() {
    return {
      steps: ['填寫資料', '確認資料', '信箱驗證'],
      schemas,
      initForm: {
        unitType: 1,
        acceptTerm: false,
      },
      isVerifyMailSend: false,
      verifyCode: '',
      verifyMailConfig: {
        email: '',
        id: null,
      },
      isFinish: false,
      FinishJSON,
    };
  },
  methods: {
    // 建立暫時會員
    async submit(formData, nextCallback) {
      this.setLoading(true);
      // 取得檔案和表單資訊
      const submitData = _.chain(formData).omit(['acceptTerm', 'confirmPassword', 'file1', 'file2', 'file3']).mapValues((val) => (val || ''));
      const files = _.pick(formData, formData.unitType === 1 ? ['file1', 'file2', 'file3'] : ['file1']);
      // 註冊 api (建立暫時會員 + 上傳檔案)
      try {
        this.verifyMailConfig.email = formData.contactEmail;
        this.verifyMailConfig.id = await api.signup(submitData);
        // 註冊錯誤
        if (this.verifyMailConfig.id === -2) throw new Error('密碼與規則不符');
        if (this.verifyMailConfig.id === -1) throw new Error('公司統編/身分證已重複註冊');
        if (this.verifyMailConfig.id === 0) throw new Error('目前系統忙碌中，請稍後再試');
        // 上傳檔案
        const fileResult = await api.uploadFilesToSignup(files, this.verifyMailConfig.id, formData.unitType);

        // 上傳錯誤
        const invalidResult = _.filter(fileResult, (resultItem) => resultItem.success === false);
        if (invalidResult.length > 0) {
          const message = _.map(invalidResult, (resultItem) => `${resultItem.fieldName}: ${resultItem.message}`).join('\n');
          throw new Error(message);
        }

        // 發送驗證信
        const sendMailResult = await api.verifyMailSend(this.verifyMailConfig);
        this.isVerifyMailSend = true;

        if (sendMailResult) {
          alert('驗證信已寄出');
        } else {
          throw new Error('驗證信發送錯誤');
        }

        nextCallback();
      } catch (error) {
        if (error.message) {
          const memberId = this.verifyMailConfig.id;
          if (memberId !== null) await api.deleteSignup({ memberId, msg: error.message });
          alert(error);
        } else {
          alert('表單送出發生錯誤，請聯絡系統工程師');
        }
      }
      this.setLoading(false);
    },
    // 寄發驗證信
    async verifyMailSend() {
      this.isVerifyMailSend = false;
      const sendMailResult = await api.verifyMailSend(this.verifyMailConfig);
      this.isVerifyMailSend = true;

      if (sendMailResult) {
        alert('驗證信已寄出');
      } else {
        alert('驗證信發送錯誤，請聯絡系統工程師');
      }
    },
    // 送出驗證
    async verifyCodeCheck() {
      this.setLoading(true);
      try {
        const isPassed = await api.verifyCodeCheck({
          id: this.verifyMailConfig.id,
          code: this.verifyCode,
        });

        if (!isPassed) throw new Error('驗證碼錯誤');

        await api.switchUserStatus({ memberId: this.verifyMailConfig.id });
        this.isFinish = true;
      } catch (error) {
        if (error.message) {
          alert(error);
        } else {
          alert('會員驗證發生錯誤，請聯絡系統工程師');
        }
      }
      this.setLoading(false);
    },
    ...mapActions(useLoadingScreen, ['setLoading']),
  },
  computed: {
    ...mapState(useFormStore, ['formData']),
  },
};
</script>
