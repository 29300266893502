<template>
  <div class="form-item">
    <div class="form-label">{{ fieldLabel }}</div>
    <div
        class="form-upload is-readonly"
        :class="fileStatus(fileInfo) === 'empty' ? 'is-empty' : 'is-active'"
      >
        <template v-if="fileInfo">
          <i class="file-icon icon" :class="fileClass(fileInfo.type)"></i>
          <span>已上傳檔案</span>
        </template>

        <template v-else>
          <i class="file-icon icon icon-file"></i>
          <span>您尚未上傳此文件</span>
        </template>
      </div>
      <!-- preview link -->
      <a
        :href="fileInfo.previewURL"
        class="form-upload-previewBtn is-uploaded mt-1"
        target="_blank"
        v-if="fileInfo"
        >檢視檔案 <i class="icon icon-external"></i
      ></a>
  </div>
</template>
<script>
import _ from 'lodash';

export default {
  props: {
    fieldLabel: String,
    readonlyInfo: {
      default: null,
    },
  },
  methods: {
    fileClass(type) {
      if (!type) return 'icon-file';
      const fileExtension = type.includes('/') ? type.split('/')[1].toLowerCase() : type;
      if (fileExtension === 'jpg' || fileExtension === 'jpeg') return 'icon-file-jpg';
      if (fileExtension === 'pdf') return 'icon-file-pdf';
      return 'icon-file';
    },
    fileStatus(currentFile) {
      const checkStatus = _.cond([
        [(file) => file && !_.isString(file), () => 'local'],
        [(file) => file && _.isString(file), () => 'server'],
        [() => true, () => 'empty'],
      ]);

      return checkStatus(currentFile);
    },
  },
  computed: {
    fileInfo() {
      function createFileinfo(file) {
        const { name, size, type } = file;
        const previewURL = file.previewURL ? file.previewURL : URL.createObjectURL(file);

        return {
          name,
          type,
          size: _.round(size / 1024 / 1024, 2),
          previewURL,
        };
      }

      if (_.isNull(this.readonlyInfo)) return null;
      if (_.isString(this.readonlyInfo)) return { previewURL: this.readonlyInfo };
      return createFileinfo(this.readonlyInfo);
    },
  },
};
</script>
